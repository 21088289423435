var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-propertyTypes animatedBox"},[_c('div',{staticClass:"container fluid"},[_c('div',{staticClass:"card"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.propertyTypesTableColumns,"rows":_vm.propertyTypesTableData,"totalRows":_vm.propertyTypesTablePagination.total,"pagination-options":{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: _vm.perPageOptions,
          dropdownAllowAll: false
        },"sort-options":{
          enabled: false
        }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-row-click":_vm.openPropertyDetail},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[_c('button',{staticClass:"btn mr-1",on:{"click":function($event){return _vm.toEditPropertyType(props.row.id)}}},[_c('i',{staticClass:"fas fa-pen"})]),_c('button',{staticClass:"btn danger",on:{"click":function($event){return _vm.deletePropertyTypes(props.row.id)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptyarea"},slot:"emptyarea"},[_c('no-data',{attrs:{"message":"There is no data"}})],1),_c('div',{staticClass:"p-2",attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('button',{staticClass:"btn main mr-2",on:{"click":_vm.toAddPropertyType}},[_c('i',{staticClass:"fas fa-plus"})]),_c('button',{staticClass:"btn toggle",class:{ active: _vm.isFilterActive },on:{"click":_vm.filterToggle}},[_c('i',{staticClass:"fas fa-filter"})])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }